"use client";

import { FORM_FLOW_ID } from "src/common/config/form-flow";
import cookies from "src/common/utils/cookies";
import { getAuthorizationHeader } from "src/common/utils/helpers";
import { OnboardingType } from "src/employee/onboarding-v2/components/types";
import { FlowKey } from "src/employee/onboarding/components/FlowContent";
import { ApiResult, fetcher } from "./api";

export interface FormFlowClientType {
  createFormFlow: (page: FlowKey) => Promise<ApiResult<{ id: number }>>;
  flowType: OnboardingType;
  getFormFlow: (page: string) => Promise<any>;
  postLogin: (...payload: (object | string)[]) => Promise<any>;
  updateFormFlow: (
    page: FlowKey,
    action: string,
    payload: object,
  ) => Promise<any>;
}

const FormFlowClient = (
  backendBaseUrl: string,
  flowType: OnboardingType,
  employerId?: string,
): FormFlowClientType => {
  const { getCookie, setCookie, deleteCookie } = cookies();

  const createFormFlow = async (page: string) => {
    const result = await fetcher<ApiResult<{ id: number }>>(
      backendBaseUrl + `/form_flow/${flowType}`,
      "POST",
      JSON.stringify({ page }),
      getAuthorizationHeader(),
    );

    if (!result?.error && result?.data?.id) {
      setCookie(FORM_FLOW_ID, result?.data?.id);
    }

    return result;
  };

  const fetchWithRetry = async (fetchMethod: () => Promise<ApiResult>) => {
    const result = await fetchMethod();

    if (result.error === "Form not found") {
      deleteCookie(FORM_FLOW_ID);
      return await fetchMethod();
    }

    return result;
  };

  const getFormFlowId = async (page: string): Promise<any> => {
    const formFlowId = getCookie(FORM_FLOW_ID);

    if (formFlowId) {
      return { id: formFlowId };
    }

    const result = await createFormFlow(page);

    if (result.error) {
      return result;
    }

    return { id: result.data?.id };
  };

  const updateFormFlow = async (
    page: string,
    action: string,
    payload: object,
  ) => {
    const fetchMethod = async () => {
      const resultId = await getFormFlowId(page);

      if (resultId.error) {
        return resultId;
      }

      const result = await fetcher(
        backendBaseUrl + `/form_flow/${flowType}/${resultId.id}/actions`,
        "POST",
        JSON.stringify({
          page,
          action,
          payload,
        }),
        getAuthorizationHeader(),
      );

      return result;
    };

    return await fetchWithRetry(fetchMethod);
  };

  async function postLogin(payload: any) {
    const fetchMethod = async () => {
      const resultId = await getFormFlowId("login");

      if (resultId.error) {
        return resultId;
      }

      const result = await fetcher(
        backendBaseUrl + `/form_flow/${flowType}/${resultId.id}/actions`,
        "POST",
        JSON.stringify({
          page: "login",
          action: "post_login",
          payload,
        }),
        getAuthorizationHeader(),
      );

      return result;
    };

    return await fetchWithRetry(fetchMethod);
  }

  const getFormFlow = async (page: string) => {
    const fetchMethod = async () => {
      const resultId = await getFormFlowId(page);
      if (resultId.error) {
        return resultId;
      }

      const result = await fetcher(
        backendBaseUrl +
          `/form_flow/${flowType}/${resultId.id}/actions${
            employerId ? `?employerId=${employerId}` : ""
          }`,
        "GET",
        null,
        getAuthorizationHeader(),
      );

      return result;
    };

    return await fetchWithRetry(fetchMethod);
  };

  return {
    flowType,
    createFormFlow,
    updateFormFlow,
    postLogin,
    getFormFlow,
  };
};

export default FormFlowClient;
